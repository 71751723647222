export const appSettings = {
  resourceLocations: {
    userLogin: "/users/v1/login",
    cxOneLogin: "/users/v1/cxone/login",
    userTokenRefresh: "/users/v1/token/issue",
    retrieveAuthenticatedUser: "/users/v1/saml/token",
    userLogout: "/users/v1/logout",
    entityList: "/esp-entity-manager/v1/entities",
    muAndMuset: "/esp-entity-manager/v1/entities/mus-musets",
    forecastList: "/esp-forecast-manager/v1/forecasts",
    forecastTemplate: "/esp-forecast-manager/v1/forecasts/template",
    forecastUploadFile: "/esp-forecast-manager/v1/forecasts/files",
    forecastSoftDelete: "/esp-forecast-manager/v1/forecasts/soft-delete",
    forecastCheckFileExists: getImportedForecastFileExistsUrl,
    forecastViewLog: getForecastViewLog,
    planList: "/esp-plan-manager/v1/plans",
    planSoftDelete: "/esp-plan-manager/v1/plans/soft-delete-plans",
    planListV2: "/esp-plan-manager/v2/plans",
    planDetail: getPlanDetailUrl,
    planDetailV2: getPlanDetailV2Url,
    loadPlanDetail: loadPlanDetailUrl,
    loadPlanDetailV2: loadPlanDetailV2Url,
    planExists: getPlanExists,
    refreshCache: "/esp-plan-manager/v1/plans/refresh-cache",
    featureList: "/wfm-saas-config-manager/v1/feature-toggles",
    convertPlan:getConvertPlanUrl,
    saveAs: "/esp-plan-manager/v1/plans/save-as",
    importedForecastsList: "/esp-forecast-manager/v1/forecasts/import-forecasts",
    muStaffing:getMuStaffingUrl,
    applyStaffying:getApplyStaffingUrl,
    applyMuParams: getApplyMuParamsUrl,
    savePlanSettings: getSavePlanSettingsUrl,
    applyFTERequired: getApplyFTERequiredUrl,
    entitySettings: "/esp-plan-manager/v1/plans/entity-settings",
    historicalDataCTs: "/esp-entity-manager/v1/entities/ct",
    historicalData: "/esp-hist-data/v1/hist-data",
    exportEveryCt: getExportEveryCtUrl,
    multiEditPlanDetails: "/esp-plan-manager/v2/plans/multi-edit",
    multiEntityEditPlanDetails: "/esp-plan-manager/v2/plans/multi-entity-edit",
    apiCredentials: "/tenant-manager/v1/tenants/user-api-credentials",
    deactivateApiCredentials: "/tenant-manager/v1/tenants/user-api-credentials/deactivate",
    ctSettings: getCtSettingsUrl,
    schedulingUnits: "/esp-entity-manager/v1/cxone/entities/scheduling-units",
    skillsAndProfiles: "/esp-forecast-manager/v1/cxone/forecasts/skills-profiles",
    cxOnePlans: "/esp-plan-manager/v1/cxone/plans",
  }
};

function getApplyFTERequiredUrl(planOid) {
  return `/esp-plan-manager/v1/plans/${planOid}/staffing-data/fte-req`;
}

function getSavePlanSettingsUrl(planOid) {
  return `/esp-plan-manager/v1/plans/${planOid}/settings`;
}

function getApplyMuParamsUrl(planOid) {
  return `/esp-plan-manager/v1/plans/${planOid}/mu-data`;
}

function getApplyStaffingUrl(planOid){
  return `/esp-plan-manager/v1/plans/${planOid}/staffing-data`;
}

export function getMuStaffingUrl(planOid,version=1){
  return `/esp-plan-manager/v${version}/plans/${planOid}/staffing-data`;
}

export function getPlanDetailUrl(planOid) {
  return `/esp-plan-manager/v1/plans/${planOid}/entities`;
}
export function getPlanDetailV2Url(planOid) {
  return `/esp-plan-manager/v2/plans/${planOid}/entity-retrieval`;
}

export function loadPlanDetailUrl(planOid) {
  return `/esp-plan-manager/v1/plans/${planOid}/load`;
}

export function loadPlanDetailV2Url(planOid) {
  return `/esp-plan-manager/v2/plans/${planOid}/load`;
}

export function getPlanExists(planName) {
  return `/esp-plan-manager/v1/plans/${planName}`;
}

export function getConvertPlanUrl(planOid){
  return `/esp-plan-manager/v1/plans/${planOid}/convert-to-forecast`;
}

export function getImportedForecastFileExistsUrl(importForecastName) {
  return `/esp-forecast-manager/v1/forecasts/import-forecasts/details/${importForecastName}`;
}

export function getForecastViewLog(importForecastOid) {
  return `/esp-forecast-manager/v1/forecasts/import-forecast-log/${importForecastOid}`;
}

export function getExportEveryCtUrl(planOid) {
  return `/esp-plan-export/v1/plans/${planOid}/csv`;
}

export function getCtSettingsUrl(planOid) {
  return `/esp-plan-manager/v1/plans/${planOid}/ct-settings`;
}
