import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  AppActionTypes, CreateCXonePlan, CreateCXonePlanFailed, CreateCXonePlanSuccess,
  GetLongTermForecasts,
  GetLongTermForecastsFailed,
  GetLongTermForecastsSuccess,
  GetSchedulingUnits,
  GetSchedulingUnitsFailed,
  GetSchedulingUnitsSuccess,
  GetSkillsAndProfiles,
  GetSkillsAndProfilesFailed,
  GetSkillsAndProfilesSuccess
} from "../actions";
import {catchError, map, mergeMap} from "rxjs/operators";
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {appSettings} from '../../app-settings';
import {PlanHelper} from '../../helpers/plan-helper';

@Injectable()
export class CxoneEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions
  ) {}

  getLongTermForecasts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetLongTermForecasts),
    map((action: GetLongTermForecasts) => action.payload),
    mergeMap(payload => {
      let url = environment.espEndpointBaseUrl +
        `${appSettings.resourceLocations.forecastList}/entries?forecast-source=${payload.value}`;
      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
      let options = {headers: headers};

      return this.http.get(url, options).pipe(
        map((response: any) => new GetLongTermForecastsSuccess(response)),
        catchError((error: any) => of(new GetLongTermForecastsFailed(error)))
      );
    })
  ));

  getSchedulingUnits$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetSchedulingUnits),
    map((action: GetSchedulingUnits) => action.payload),
    mergeMap(payload => {
      let url = environment.espEndpointBaseUrl + appSettings.resourceLocations.schedulingUnits;
      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
      let options = {headers: headers};

      return this.http.get(url, options).pipe(
        map((response: any) => new GetSchedulingUnitsSuccess(response)),
        catchError((error: any) => of(new GetSchedulingUnitsFailed(error)))
      );
    })
  ));

  getSkillsAndProfiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetSkillsAndProfiles),
    map((action: GetSkillsAndProfiles) => action.payload),
    mergeMap(payload => {
      let url = environment.espEndpointBaseUrl + appSettings.resourceLocations.skillsAndProfiles;
      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
      let options = {headers: headers};
      const params = {'forecast-source': payload.forecast_source, 'forecast-id': payload.forecast_id};
      let urlParameters = encodeURI(Object.entries(params).map(e => e.join("=")).join("&"));

      return this.http.get(url + `?${urlParameters}`, options).pipe(
        map((response: any) => new GetSkillsAndProfilesSuccess(response)),
        catchError((error: any) => of(new GetSkillsAndProfilesFailed(error)))
      );
    })
  ));

  createCXonePlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.CreateCXonePlan),
    map((action: CreateCXonePlan) => action.payload),
    mergeMap(payload => {
      let url = environment.espEndpointBaseUrl + appSettings.resourceLocations.cxOnePlans;

      return this.http.post(url, payload).pipe(
        map((response: any) => new CreateCXonePlanSuccess(response)),
        catchError((error: any) => of(new CreateCXonePlanFailed(error)))
      );
    })
  ));
}
